import React, { createContext, useContext, PropsWithChildren, Dispatch } from 'react'

import { useReducer } from 'reinspect'

import reducer, { INITIAL_STATE } from './reducer'
import { RootActions, RootState } from './reducer/types'

export type GlobalStateContextData = [RootState, Dispatch<RootActions>]

const GlobalStateContext = createContext<GlobalStateContextData | null>(null)

export const GlobalStateProvider = ({ children }: PropsWithChildren<unknown>) => {
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE, (store) => store, 'globalStore')

  return (
    <GlobalStateContext.Provider value={[state, dispatch]}>{children}</GlobalStateContext.Provider>
  )
}

export const GlobalStateProviderTest = ({
  children,
  initialState,
}: {
  initialState: RootState
} & PropsWithChildren<unknown>) => {
  const [state, dispatch] = useReducer(reducer, initialState, (store) => store, 'globalStore')

  return (
    <GlobalStateContext.Provider value={[state, dispatch]}>{children}</GlobalStateContext.Provider>
  )
}
export const useGlobalState = () => {
  const context = useContext(GlobalStateContext)

  if (!context) {
    throw new Error(`useGlobalState must be used within a GlobalStateProvider`)
  }

  return context
}

export default useGlobalState
