export enum DeepLinksPF {
  QR_CODE = 'bancointer://leituraQrCode',
  BABI = 'bancointer://interchat',
}

export enum DeepLinksPJ {
  QR_CODE = 'bancointerpj://leituraQrCode',
}

export type DeepLinksKeys = keyof typeof DeepLinksPF | keyof typeof DeepLinksPJ
export const DeepLinks = {
  ...Object.keys(DeepLinksPF).reduce(
    (accumulator, currentValue) => ({
      ...accumulator,
      [currentValue]: currentValue,
    }),
    {},
  ),
  ...Object.keys(DeepLinksPJ).reduce(
    (accumulator, currentValue) => ({
      ...accumulator,
      [currentValue]: currentValue,
    }),
    {},
  ),
} as Record<DeepLinksKeys, DeepLinksKeys>
